@charset 'utf-8';

@import url('https://fonts.googleapis.com/css?family=Roboto');
@import url('https://fonts.googleapis.com/css?family=Share+Tech+Mono');

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

.prose pre {
  color: unset;
  background-color: #f7f7f7;
}
